import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Home from '../containers/Home'
import Main from './Main'

class Content extends Component {
  constructor(props) {
    super()
    this.page = props.page
    this.path = props.location.pathname // ここで現在のパスを取得
  }

  render() {
    let pathName = this.path.replace(/\//g, '')
    if (pathName === '') pathName = 'home'

    let main
    if (pathName === 'home') {
      main = <Home />
    } else {
      main = <Main page={this.page} />
    }
    return <div className="content">{main}</div>
  }
}

export default withRouter(Content)

Content.propTypes = {
  page: PropTypes.string,
  location: PropTypes.object,
}

Content.defaultProps = {
  page: null,
  location: null,
}

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import GLOBAL from '../Global'

let keepState = ''
class ScheduleList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      toggle: false,
      text: 'change style to list',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      noMatch: false,
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (data.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
    }
    if (keepState !== '') {
      this[keepState](data)
    }
  }

  handleChange = event => {
    const {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
    } = this.state
    const { data } = this.props
    const name = event.currentTarget.value
    const checked = event.target.checked
    this.setState({
      current: 'all',
      [name]: checked,
    })
    const selectedCategory = {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      [name]: checked,
    }

    Object.keys(selectedCategory).forEach(key => {
      if (selectedCategory[key] === false) delete selectedCategory[key]
    })
    const categories = Object.keys(selectedCategory)

    const matchData = e => {
      const targetArrays = [...categories, ...e.areaCategory]
      const match = targetArrays.filter(
        item => categories.includes(item) && e.areaCategory.includes(item)
      )
      return match.length
    }

    const selectedData = data.filter(e => matchData(e) !== 0)

    // カテゴリー一個に対してだけなら下記でもOK
    // const selectedData = data.filter(
    //   e => categories.indexOf(e.areaCategory[0]) !== -1
    // )
    if (selectedData.length === 0) {
      this.setState({ noMatch: true })
    } else {
      this.setState({
        stateData: selectedData,
        noMatch: false,
      })
    }
  }

  toggleStyle = () => {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
    } else {
      this.setState({
        text: 'change style to table',
      })
    }
  }

  removeAllCheck = () => {
    this.setState({
      midkniteeuro: false,
      midkniteusa: false,
      midkniteasia: false,
      midknitepacific: false,
      midknitelatin: false,
      midkniteafrica: false,
      midknitejapan: false,
      midkniteblue: false,
      noMatch: true,
    })
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      noMatch: false,
    })
  }

  all(data) {
    this.stateInitialize()
    this.setState({ stateData: data })
  }

  international(data) {
    this.stateInitialize()
    const international = data.filter(
      e => e.parentCategory[0] === 'international'
    )
    this.setState({ stateData: international, current: 'international' })
  }

  domestic(data) {
    this.stateInitialize()
    const domestic = data.filter(e => e.parentCategory[0] === 'domestic')
    this.setState({ stateData: domestic, current: 'domestic' })
  }

  event(data) {
    this.stateInitialize()
    const event = data.filter(e => e.parentCategory[0] === 'event')
    this.setState({ stateData: event, current: 'event' })
  }

  midkniteathletics(data) {
    this.stateInitialize()
    const midkniteathletics = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'midkniteathletics'
    )
    this.setState({
      stateData: midkniteathletics,
      current: 'midkniteathletics',
    })
  }

  midknitetreks(data) {
    this.stateInitialize()
    const midknitetreks = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'midknitetreks'
    )
    this.setState({ stateData: midknitetreks, current: 'midknitetreks' })
  }

  humankind(data) {
    this.stateInitialize()
    const humankind = data.filter(
      e => e.brandCategory && e.brandCategory[0] === 'humankind'
    )
    this.setState({ stateData: humankind, current: 'humankind' })
  }

  activeClass(category) {
    const { current } = this.state
    if (category === current) {
      return 'current'
    }
    return null
  }

  render() {
    const {
      stateData,
      current,
      toggle,
      text,
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      noMatch,
    } = this.state

    keepState = current

    const { data } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const reverseData = selectedData.concat()

    return (
      <div>
        <div className="tourCategory">
          <Button
            className={current === 'all' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.all(data)
            }}
          >
            ALL
          </Button>
          <Button
            className={current === 'international' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.international(data)
            }}
          >
            INTERNATIONAL
          </Button>
          <Button
            className={current === 'domestic' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.domestic(data)
            }}
          >
            DOMESTIC
          </Button>
          <Button
            className={current === 'event' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.event(data)
            }}
          >
            EVENT
          </Button>
          {/* <Button
            className={current === 'midkniteathletics' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midkniteathletics(data)
            }}
          >
            MID KNITE ATHLETICS
          </Button>
          <Button
            className={current === 'midknitetreks' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.midknitetreks(data)
            }}
          >
            MID KNITE TREKS
          </Button>
          <Button
            className={current === 'humankind' ? 'current' : ''}
            variant="outlined"
            onClick={() => {
              this.humankind(data)
            }}
          >
            HUMANKIND
          </Button> */}
        </div>

        {noMatch ? (
          <div>NO DATA MATCHED</div>
        ) : (
          <ul className="list">
          {selectedData.map(e => {
            const link = `/schedule/${e.slug}`
            const tags = e.parentCategory.map(c => c.toUpperCase())
            if (
              e.dependency === 'parent' ||
              e.showSchedule === 'hidden' ||
              e.show === 'hidden'
            )
              return null
            if (e.date.end) {
              return (
                <li key={e.tourId}>
                  <span className="date">
                    <div className="tagBox">
                      {tags.map((tag, i) => {
                        return (
                          <span key={tag} className={`tag ${e.parentCategory[i]}`}>
                            {tag}
                          </span>
                        )
                      })}
                    </div>
                    {e.date.start}〜{e.date.end}
                  </span>
                  <Link to={link}>{e.title}</Link>
                </li>
              )
            }
            return (
              <li key={e.tourId}>
                <span className="date">
                  <div className="tagBox">
                    {tags.map((tag, i) => {
                      return (
                        <span key={tag} className={`tag ${e.parentCategory[i]}`}>
                          {tag}
                        </span>
                      )
                    })}
                  </div>
                  {e.date.start}
                </span>
                <Link to={link}>{e.title}</Link>
              </li>
            )
          })}
        </ul>
        )}
      </div>
    )
  }
}

export default withRouter(ScheduleList)

ScheduleList.propTypes = {
  data: PropTypes.array,
}

ScheduleList.defaultProps = {
  data: null,
}

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import '../css/Home.css'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import LinkIcon from '@material-ui/icons/Link'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import TheatersIcon from '@material-ui/icons/Theaters'
import HmkIcon from '../icons/HmkIcon'
import Topics from './Topics'
import GLOBAL from '../Global'
import MyButton from '../modules/MyButton'
import mainVisual from '../images/main-visual.jpg'
import mainText from '../images/main-text.png'
import comingup from '../images/madeinjapan_summer.jpg'
import comingup2 from '../images/thelastfirst.jpg'
import mkj from '../images/mkj.jpg'
import instagram from '../images/img-instagram.png'
import youtube from '../images/img-youtube.png'
import neon from '../images/neon.png'
import billboard from '../images/billboard.jpg'

class Home extends Component {
  componentDidMount() {
    document.title = GLOBAL.SITENAME
  }

  render() {
    return (
      <>
        <div className="mainVisualHome">
          <div className="mainVisualInner">
            <h1>
            <span className="mainVisualText type1">HIROTAKA MATSUMOTO +</span>
            <span className="mainVisualText type2">MICHIHIRO KOSEKI =</span>
            <span className="mainVisualText type3">HUMANKIND</span>
            <span className="mainVisualText type4">SINCE 2020</span>
            <span className="mainVisualText type5">WE ARE READY TO TAKE OFF</span>
            <span className="mainVisualText type51">WE ARE READY TO</span>
            <span className="mainVisualText type52">TAKE OFF</span>
            <span className="mainVisualText type6">TO THE WORLD!</span>
            </h1>
            <div className="billboard">
                <img src={billboard} alt="" />
            </div>
          </div>
        <img src={mainVisual} alt="HUMANKIND OFFICIAL WEB PAGE" />
        </div>
        <main className="home">
          <h2 className="heading02">
            <HmkIcon />
            {GLOBAL.HOME.TOPICSTITLE}
          </h2>
          <div className="homeTopics">
            <Topics max="4" />
            <MyButton link="/topics" text="more" />
          </div>
          <div className="homeContentBox">
            <div className="homeContentPart">
              <h2 className="heading02">
                <NewReleasesIcon />
                {GLOBAL.HOME.CONTENT_TITLE1}
              </h2>
              <div className="imageArea">
                <Link to="/schedule/thelastfirst">
                  <img src={comingup2} alt={GLOBAL.HOME.CONTENT_TITLE1} />
                </Link>
              </div>
            </div>

            <div className="homeContentPart">
              <h2 className="heading02">
                <PhotoCameraIcon />
                {GLOBAL.HOME.CONTENT_TITLE2}
              </h2>
              <div className="imageArea">
                <img src={instagram} alt={GLOBAL.HOME.CONTENT_TITLE2} />
              </div>
              {/* <div className="imageArea">
                <img src={youtube} alt={GLOBAL.HOME.CONTENT_TITLE3} />
              </div> */}
            </div>
            <div className="homeContentPart">
              <h2 className="heading02">
                <TheatersIcon />
                {GLOBAL.HOME.CONTENT_TITLE3}
              </h2>
              <div className="imageArea">
              <img src={youtube} alt={GLOBAL.HOME.CONTENT_TITLE3} />
              </div>
            </div>
            <div className="homeContentPart">
              {/* <h2 className="heading02">
                <PhotoCameraIcon />
                {GLOBAL.HOME.CONTENT_TITLE2}
              </h2> */}

              <div className="imageArea">
                <img src={neon} alt="" />
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default withRouter(Home)

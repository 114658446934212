import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'

class BreadCrumb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    const { category } = this.props
    // SCHEDULEはTOUR APIを利用
    let updatedCategory = category
    if (category === 'schedule') {
      updatedCategory = 'tour'
    }
    let endPoint
    if (updatedCategory === 'tour') {
      endPoint = '/data/tour.json'
    } else {
      endPoint = '/data/topics.json'
    }
    axios.get(endPoint).then(result => {
      this.setState({ data: result.data })
    })
  }

  render() {
    const {
      state: { data },
      props: { location },
    } = this
    const splitPath = location.pathname.split('/').filter(e => e !== '')
    return (
      <Breadcrumbs
        className="breadCrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/">
          HOME
        </Link>
        {splitPath.map((element, i) => {
          if (i + 1 >= splitPath.length) {
            if (i === 0) {
              const categoryName = element.toUpperCase()
              return (
                <Typography key={element} color="textPrimary">
                  {categoryName}
                </Typography>
              )
            }
            const matchData = Array.from(data).filter(e => e.slug === element)
            if (matchData[0]) {
              return (
                <Typography key={element} color="textPrimary">
                  {matchData[0].title}
                </Typography>
              )
            }
            return null
          }
          const categoryName = element.toUpperCase()
          return (
            <Link key={element} color="inherit" href={`/${element}`}>
              {categoryName}
            </Link>
          )
        })}
      </Breadcrumbs>
    )
  }
}

export default withRouter(BreadCrumb)

BreadCrumb.propTypes = {
  category: PropTypes.string,
  location: PropTypes.object,
}
BreadCrumb.defaultProps = {
  category: null,
  location: null,
}

/* eslint-disable react/no-danger */
import React from 'react'
import { withRouter } from 'react-router-dom'
import GLOBAL from '../Global'
import humankind from '../images/humankind.jpg'

const About = () => {
  return (
    <>
      <h1 className="heading01">{GLOBAL.ABOUT.TITLE}</h1>
      {/* <div dangerouslySetInnerHTML={{ __html: GLOBAL.ABOUT.TEXT }} /> */}
      <div>
        <div
          style={{
            margin: '0 auto 40px',
            maxWidth: '400px',
          }}
        >
          <img src={humankind} alt="" />
        </div>
        <p>HUMANKIND: 【意味】人類、人間</p>
        <p>
          人間であること以外の共通点を、二人はこの旅でいくつ見つけることができるだろうか。
        </p>
      </div>
    </>
  )
}

export default withRouter(About)

export default {
  SITENAME: 'HUMANKIND OFFICIAL WEB PAGE',
  HOME: {
    MAINTEXT: 'HUMANKIND IS READY TO TAKE OFF!',
    TOPICSTITLE: "WHAT'S NEW",
    CONTENT_TITLE1: 'COMING UP',
    CONTENT_TITLE2: 'PHOTO',
    CONTENT_TITLE3: 'MOVIE',
  },
  ABOUT: {
    TITLE: 'ABOUT',
    TEXT:
      '<p>HUMANKIND: 意味[人類、人間]<br />人間であること以外の共通点を、二人はこの旅でいくつ見つけることができるだろうか。</p>',
  },
  TOPICS: {
    TITLE: 'TOPICS',
  },
  SCHEDULE: {
    TITLE: 'SCHEDULE',
  },
  TOUR: {
    TITLE: 'TOUR',
    INFORMATION: 'INFORMATION',
    COOPERATIONS: 'COOPERATIONS',
    NOTE: 'NOTE',
    ROUTEMAP: 'ROUTE MAP',
    SCHEDULE_TITLE: 'SCHEDULE',
    SCHEDULE: {
      DATE: 'DATE',
      CITY: 'CITY',
      COUNTRY: 'COUNTRY',
    },
    FROM_HUMANKIND_TITLE: 'FROM HUMANKIND',
  },
  COPYRIGHT: '&copy;MID KNITE JAPAN INTERNATIONAL ALL RIGHTS RESERVED.',
}

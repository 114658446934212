import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Schedule from '../containers/Schedule'
import About from '../containers/About'
import Topics from '../containers/Topics'
import Tour from '../containers/Tour'
import NotFound from '../containers/NotFound'
import BreadCrumb from './BreadCrumb'
import GLOBAL from '../Global'

class Main extends Component {
  constructor(props) {
    super()
    this.props = props
    this.page = props.page
    this.path = props.location.pathname.replace(/\//g, '') // ここで現在のパスを取得
  }

  componentDidMount() {
    const { page } = this.props
    document.title = `${page} | ${GLOBAL.SITENAME}`
  }

  render() {
    const { match } = this.props
    const path = match.path.replace(/\//g, '')
    let pageContent
    if (path === 'schedule') {
      pageContent = <Schedule />
    } else if (path === 'topics') {
      pageContent = <Topics />
    } else if (path === 'about') {
      pageContent = <About />
    } else if (path === 'tour') {
      pageContent = <Tour />
    } else {
      pageContent = <NotFound />
    }

    return (
      <main className={path}>
        <BreadCrumb category={path} />
        {pageContent}
      </main>
    )
  }
}

export default withRouter(Main)

Main.propTypes = {
  page: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
}

Main.defaultProps = {
  page: null,
  location: null,
  match: null,
}

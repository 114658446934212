/* eslint-disable react/no-danger */
import React from 'react'
import '../css/Footer.css'
import GLOBAL from '../Global'

const Footer = () => {
  return (
    <footer className="footer">
      <div dangerouslySetInnerHTML={{ __html: GLOBAL.COPYRIGHT }} />
    </footer>
  )
}

export default Footer

import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import GridOnIcon from '@material-ui/icons/GridOn'

let keepState = false
class TopicsList extends Component {
  constructor() {
    super()
    this.state = {
      toggle: keepState || false,
      text: 'change style to list',
    }
  }

  componentDidMount() {
    if (keepState) {
      this.setState({
        toggle: true,
      })
    }
  }

  toggleStyle() {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
      keepState = false
    } else {
      this.setState({
        text: 'change style to table',
      })
      keepState = true
    }
  }

  render() {
    const { data, max } = this.props
    const { toggle, text } = this.state
    const reverseData = data.concat()
    return (
      <>
        {!max ? (
          <Button
            size="small"
            className="toggleButton"
            // variant="contained"
            onClick={() => {
              this.toggleStyle(this)
            }}
          >
            {toggle ? <GridOnIcon /> : <ListIcon />}
            {text}
          </Button>
        ) : (
          ''
        )}

        <ul className={`list archive${toggle ? ' listStyle' : ''}`}>
          {reverseData.reverse().map((e, i) => {
            const link = `/topics/${e.slug}`
            const tag = e.tag.toUpperCase()
            let image
            if (e.image !== '') {
              image = `topics/${e.image}`
            } else {
              image = 'img-default.png'
            }
            if (max) {
              if (i < max) {
                return (
                  <li key={e.slug}>
                    <span className="date">
                      {e.date} <span className={`tag ${e.tag}`}>{tag}</span>
                    </span>
                    <Link to={link}>
                      <div className="thumbnail">
                        <img src={`/images/${image}`} alt={e.title} />
                      </div>
                      {e.title}
                    </Link>
                  </li>
                )
              }
              return null
            }
            return (
              <li key={e.slug}>
                <span className="date">
                  {e.date} <span className={`tag ${e.tag}`}>{tag}</span>
                </span>
                <Link to={link}>
                  <div className="thumbnail">
                    <img src={`/images/${image}`} alt={e.title} />
                  </div>
                  {e.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}

export default withRouter(TopicsList)

TopicsList.propTypes = {
  data: PropTypes.array,
  max: PropTypes.string,
}

TopicsList.defaultProps = {
  data: null,
  max: null,
}

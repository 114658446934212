/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import PublicIcon from '@material-ui/icons/Public'
import EventNoteIcon from '@material-ui/icons/EventNote'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LanguageIcon from '@material-ui/icons/Language'
import AttachmentIcon from '@material-ui/icons/Attachment'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'
import NotFound from './NotFound'
import GLOBAL from '../Global'
import TourMap from './TourMap'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#5B01CF',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

class ScheduleSingle extends Component {
  setTitle = title => {
    document.title = `${title} | SCHEDULE | ${GLOBAL.SITENAME}`
  }

  render() {
    const {
      props: { category, data },
    } = this
    if (!data) return <NotFound />
    this.setTitle(data.title)
    let mainVisual = ''
    if (data.mainVisual) mainVisual = `/${data.mainVisual}`
    let date = ''
    if (data.date.end) {
      date = `${data.date.start}〜${data.date.end}`
    } else {
      date = data.date.start
    }
    let parentCategory = ''
    if (data.parentCategory[0] === 'event') {
      parentCategory = 'EVENT '
    } else {
      parentCategory = 'TOUR '
    }
    return (
      <div className={`single ${category}`}>
        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
        <div className="tagContainer">
          {data.parentCategory.map(e => (
            <span key={e} className={`tag ${e}`}>
              {e.toUpperCase()}
            </span>
          ))}
        </div>
        {mainVisual !== '' && (
          <div className="mainVisual">
            <img src={`/images/tour/${mainVisual}`} alt={data.title} />
          </div>
        )}
        <div
          className="infoText"
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
        <h2 className="heading02">
          <ImportContactsIcon />
          {parentCategory}
          {GLOBAL.TOUR.INFORMATION}
        </h2>
        <Paper className="info">
          <h3 className="heading03">
            <DateRangeIcon />
            {GLOBAL.TOUR.SCHEDULE.DATE}
          </h3>
          <div className="date">{date}</div>
          <h3 className="heading03">
            <LanguageIcon />
            {GLOBAL.TOUR.SCHEDULE.COUNTRY}
          </h3>
          <div className="country">
            {data.countryCategory.map(e => (
              <span key={e} className="countryName">
                <img
                  src={`/images/flags/${e
                    .toLowerCase()
                    .replace(/\s+/g, '')}.png`}
                  alt={e}
                />
                {e}
              </span>
            ))}
          </div>
          {data.cooperations && (
            <>
              <h3 className="heading03">
                <AirplanemodeActiveIcon />
                {GLOBAL.TOUR.COOPERATIONS}
              </h3>
              <div className="coperations">{data.cooperations}</div>
            </>
          )}
          {data.note && (
            <>
              <h3 className="heading03">
                <AttachmentIcon />
                {GLOBAL.TOUR.NOTE}
              </h3>
              <div className="note">{data.note}</div>
            </>
          )}
        </Paper>
        {data.map && (
          <>
            <h2 className="heading02">
              <PublicIcon />
              {GLOBAL.TOUR.ROUTEMAP}
            </h2>
            <TourMap data={data.map} />
          </>
        )}
        {data.schedule && (
          <>
            <h2 className="heading02">
              <EventNoteIcon />
              {parentCategory}
              {GLOBAL.TOUR.SCHEDULE_TITLE}
            </h2>
            <TableContainer className="scheduleTable" component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.DATE}
                    </StyledTableCell>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.CITY}
                    </StyledTableCell>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.COUNTRY}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.schedule.map((e,i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {e.date}
                      </StyledTableCell>
                      <StyledTableCell>{e.city}</StyledTableCell>
                      <StyledTableCell>
                        <div className="countryCell">
                          <img
                            src={`/images/flags/${e.country
                              .toLowerCase()
                              .replace(/\s+/g, '')}.png`}
                            alt={e.country}
                            className="scheduleFlag"
                          />
                          {e.country}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {/* <h2 className="heading02">
          <HmkIcon />
          {GLOBAL.TOUR.FROM_HUMANKIND_TITLE}
        </h2>
        <Paper className="info">MESSAGE FROM HUMANKIND HERE</Paper> */}
      </div>
    )
  }
}

export default withRouter(ScheduleSingle)

ScheduleSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
}

ScheduleSingle.defaultProps = {
  data: null,
  category: null,
}

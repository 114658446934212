/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import UpdateIcon from '@material-ui/icons/Update'
import GLOBAL from '../Global'
import NotFound from './NotFound'

class TopicsSingle extends Component {
  setTitle = title => {
    document.title = `${title} | TOPICS | ${GLOBAL.SITENAME}`
  }

  render() {
    const {
      props: { category, data },
    } = this
    if (!data) return <NotFound />
    this.setTitle(data.title)

    let image
    if (data.image !== '') {
      image = `topics/${data.image}`
    } else {
      image = 'img-default.png'
    }

    return (
      <div className={`single ${category}`}>
        <h1>{data.title}</h1>
        <div className="dateCreated">
          <UpdateIcon /> {data.date} UPDATED
        </div>
        <div className="tagContainer">
          <div className={`tag ${data.tag}`}>{data.tag.toUpperCase()}</div>
        </div>
        {data.image && (
          <div className="mainVisual">
            <img src={`/images/${image}`} alt={data.title} />
          </div>
        )}
        <div className="text" dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>
    )
  }
}

export default withRouter(TopicsSingle)

TopicsSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
}

TopicsSingle.defaultProps = {
  data: null,
  category: null,
}

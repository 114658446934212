import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import GridOnIcon from '@material-ui/icons/GridOn'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

let keepState = ''
let keepToggleState = false
class TourList extends Component {
  constructor() {
    super()
    this.state = {
      stateData: [],
      current: keepState !== '' ? keepState : 'all',
      toggle: keepToggleState || false,
      text: 'change style to list',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      noMatch: false,
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (data.length === 0) {
      this.setState({ current: 'all' })
      keepState = ''
    }
    if (keepState !== '') {
      this[keepState](data)
    }
    if (keepToggleState) {
      this.setState({
        toggle: true,
      })
    }
  }

  handleChange = event => {
    const {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
    } = this.state
    const { data } = this.props
    const name = event.currentTarget.value
    const checked = event.target.checked
    this.setState({
      current: 'all',
      [name]: checked,
    })
    const selectedCategory = {
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      [name]: checked,
    }

    Object.keys(selectedCategory).forEach(key => {
      if (selectedCategory[key] === false) delete selectedCategory[key]
    })
    const categories = Object.keys(selectedCategory)

    const matchData = e => {
      const targetArrays = [...categories, ...e.areaCategory]
      const match = targetArrays.filter(
        item => categories.includes(item) && e.areaCategory.includes(item)
      )
      return match.length
    }

    const selectedData = data.filter(e => matchData(e) !== 0)

    // カテゴリー一個に対してだけなら下記でもOK
    // const selectedData = data.filter(
    //   e => categories.indexOf(e.areaCategory[0]) !== -1
    // )
    if (selectedData.length === 0) {
      this.setState({ noMatch: true })
    } else {
      this.setState({
        stateData: selectedData,
        noMatch: false,
      })
    }
  }

  toggleStyle = () => {
    const { toggle } = this.state
    this.setState({ toggle: !toggle })
    if (toggle) {
      this.setState({
        text: 'change style to list',
      })
      keepToggleState = false
    } else {
      this.setState({
        text: 'change style to table',
      })
      keepToggleState = true
    }
  }

  removeAllCheck = () => {
    this.setState({
      midkniteeuro: false,
      midkniteusa: false,
      midkniteasia: false,
      midknitepacific: false,
      midknitelatin: false,
      midkniteafrica: false,
      midknitejapan: false,
      midkniteblue: false,
      noMatch: true,
    })
  }

  stateInitialize = () => {
    this.setState({
      current: 'all',
      midkniteeuro: true,
      midkniteusa: true,
      midkniteasia: true,
      midknitepacific: true,
      midknitelatin: true,
      midkniteafrica: true,
      midknitejapan: true,
      midkniteblue: true,
      noMatch: false,
    })
  }

  all(data) {
    this.stateInitialize()
    this.setState({ stateData: data })
  }

  international(data) {
    this.stateInitialize()
    const international = data.filter(
      e => e.parentCategory[0] === 'international'
    )
    this.setState({ stateData: international, current: 'international' })
  }

  domestic(data) {
    this.stateInitialize()
    const domestic = data.filter(e => e.parentCategory[0] === 'domestic')
    this.setState({ stateData: domestic, current: 'domestic' })
  }

  event(data) {
    this.stateInitialize()
    const event = data.filter(e => e.parentCategory[0] === 'event')
    this.setState({ stateData: event, current: 'event' })
  }

  activeClass(category) {
    const { current } = this.state
    if (category === current) {
      return 'current'
    }
    return null
  }

  render() {
    const {
      stateData,
      current,
      toggle,
      text,
      midkniteeuro,
      midkniteusa,
      midkniteasia,
      midknitepacific,
      midknitelatin,
      midkniteafrica,
      midknitejapan,
      midkniteblue,
      noMatch,
    } = this.state

    keepState = current

    const { data } = this.props
    let selectedData
    if (stateData.length === 0) {
      selectedData = data
    } else {
      selectedData = stateData
    }
    const reverseData = selectedData.concat()

    return (
      <div>
        <div className="tourCategory">
          <Button
            className={current === 'all' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.all(data)
            }}
          >
            ALL
          </Button>
          <Button
            className={current === 'international' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.international(data)
            }}
          >
            INTERNATIONAL
          </Button>
          <Button
            className={current === 'domestic' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.domestic(data)
            }}
          >
            DOMESTIC
          </Button>
          <Button
            className={current === 'event' ? 'current' : ''}
            variant="contained"
            onClick={() => {
              this.event(data)
            }}
          >
            EVENT
          </Button>
        </div>
        <Button
          size="small"
          className="toggleButton"
          // variant="contained"
          onClick={this.toggleStyle}
        >
          {toggle ? <GridOnIcon /> : <ListIcon />}
          {text}
        </Button>

        {/* <div className="expansionPanel">
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>CUSTOMIZE CONDITIONS</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="checkBox">
                <div className="handleCheckButton">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<CheckBoxIcon />}
                    onClick={() => {
                      this.all(data)
                    }}
                  >
                    Check all conditions
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<IndeterminateCheckBoxIcon />}
                    onClick={this.removeAllCheck}
                  >
                    Remove all conditions
                  </Button>
                </div>
                <FormControl component="fieldset" className="formControl">
                  <FormLabel component="legend">
                    Display Tours that the conditon is matched
                  </FormLabel>
                  <FormGroup className="formGroup">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteeuro}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteeuro"
                        />
                      }
                      label="MID KNITE €URO"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteusa}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteusa"
                        />
                      }
                      label="MID KNITE U$A"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteasia}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteasia"
                        />
                      }
                      label="MID KNITE ASIA"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitepacific}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitepacific"
                        />
                      }
                      label="MID KNITE PACIFIC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitelatin}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitelatin"
                        />
                      }
                      label="MID KNITE LATIN"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteafrica}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteafrica"
                        />
                      }
                      label="MID KNITE AFRICA"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midknitejapan}
                          onChange={this.handleChange}
                          color="primary"
                          value="midknitejapan"
                        />
                      }
                      label="MID KNITE JAPAN"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={midkniteblue}
                          onChange={this.handleChange}
                          color="primary"
                          value="midkniteblue"
                        />
                      }
                      label="MID KNITE BLUE"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div> */}

        {noMatch ? (
          <div>NO DATA MATCHED</div>
        ) : (
          <ul className={`list archive${toggle ? ' listStyle' : ''}`}>
            {reverseData.reverse().map((e, i) => {
              const link = `/tour/${e.slug}`
              const mainVisual = `/images/tour/${e.mainVisual}`
              const category = e.parentCategory[0].toUpperCase()
              if (e.dependency === 'child' || e.show === 'hidden') return null
              return (
                <li key={i}>
                  <span className="date">
                    <span className={`tag ${e.parentCategory[0]}`}>
                      {category}
                    </span>
                  </span>
                  <Link to={link}>
                    <div className="thumbnail">
                      <img src={mainVisual} alt={e.title} />
                    </div>
                    {e.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  }
}

export default withRouter(TourList)

TourList.propTypes = {
  data: PropTypes.array,
}

TourList.defaultProps = {
  data: null,
}

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import PropTypes from 'prop-types'
import TopicsList from './TopicsList'
import Single from '../components/Single'
import GLOBAL from '../Global'
import '../css/Topics.css'

const END_POINT = '/data/topics.json'

class Topics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
    }
  }

  componentDidMount() {
    axios.get(END_POINT).then(result => {
      this.setState({ topics: result.data })
    })
  }

  render() {
    const {
      props: { history, max },
      state: { topics },
    } = this
    const path = history.location.pathname // 現在のパス
    if (path === '/topics/' || path === '/topics') {
      return (
        <div>
          <h1 className="heading01">{GLOBAL.TOPICS.TITLE}</h1>
          <TopicsList data={topics} />
        </div>
      )
    }
    if (path === '/') {
      // HOME出力用
      return (
        <div>
          <TopicsList max={max} data={topics} />
        </div>
      )
    }
    return (
      <div>
        <Single category="topics" data={topics} path={path} />
      </div>
    )
  }
}

export default withRouter(Topics)

Topics.propTypes = {
  history: PropTypes.object,
  max: PropTypes.string,
}

Topics.defaultProps = {
  history: null,
  max: '10',
}
